import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Post } from '../model/post';

@Injectable({
  providedIn: "root",
})
export class PostResolver implements Resolve<Post> {
  constructor(private db: AngularFirestore) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Post> {
    return this.db
      .collection("posts", (ref) => {
        return ref.where("slug", "==", route.params.postSlug);
      })
      .get()
      .pipe(
        map((doc) => {
          return doc.docs && doc.docs.length > 0
            ? (doc.docs[0].data() as Post)
            : undefined;
        })
      );
  }
}
