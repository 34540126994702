import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AngularFirestore, DocumentSnapshot } from '@angular/fire/firestore';
import { BehaviorSubject } from 'rxjs';
import { delay, take } from 'rxjs/operators';
import { Post } from 'src/app/model/post';

@Component({
  selector: "app-post-list-container",
  templateUrl: "./post-list-container.component.html",
  styleUrls: ["./post-list-container.component.scss"],
})
export class PostListContainerComponent implements OnInit, AfterViewInit {
  posts$: BehaviorSubject<Post[]> = new BehaviorSubject<Post[]>([]);
  nextCursor: DocumentSnapshot<Post>;
  numberPage = 0;
  queryLoading = false;
  obs: IntersectionObserver;

  constructor(private db: AngularFirestore) {}

  ngOnInit(): void {
    this.getPosts();
  }
  ngAfterViewInit(): void {
    this.obs = new IntersectionObserver(
      (entries, observer) => {
        if (entries[0].isIntersecting && !this.queryLoading) {
          this.getPosts();
        }
      },
      { threshold: 0.75 }
    );
    this.obs.observe(document.getElementById("loading"));
  }

  trackByFn(_: number, item: Post): string {
    return item.slug;
  }

  private getPosts(): void {
    this.queryLoading = true;
    const collection = this.nextCursor
      ? this.db.collection("posts", (ref) =>
          ref.orderBy("date", "desc").limit(5).startAfter(this.nextCursor)
        )
      : this.db.collection("posts", (ref) =>
          ref.orderBy("date", "desc").limit(5)
        );

    collection
      .get()
      .pipe(delay(250), take(1))
      .subscribe((posts) => {
        this.queryLoading = false;

        this.nextCursor = posts.docs[
          posts.docs.length - 1
        ] as DocumentSnapshot<Post>;

        this.posts$.next([
          ...this.posts$.value,
          ...posts.docs.map((doc) => doc.data() as Post),
        ]);
      });
  }

  //   private getContent(postContent: string): string {
  //     const content = postContent
  //       .replace(/\\r/g, "<br />")
  //       .replace(/\\n/g, "")
  //       .replace(/\\/g, "");

  //     const finalContent = this.setToArray(getUrls(content))
  //       .map((url) => this.htmlFor(url))
  //       .filter((html) => html)
  //       .join("<br />");

  //     if (finalContent && finalContent !== "") {
  //       return finalContent;
  //     } else {
  //       return this.setToArray(getUrls(content)).join("<br />");
  //     }
  //   }

  //   private setToArray(urls: Set<string>): string[] {
  //     return Array.from(urls);
  //   }

  //   private goodPosts(posts: any): any[] {
  //     const firstPass = TDJ.tdj_posts.filter(
  //       (post) =>
  //         post.post_date_gmt !== "0000-00-00 00:00:00" &&
  //         post.post_parent === "0" &&
  //         post.post_type === "post" &&
  //         post.post_author === "2"
  //     );

  //     const secondPass = firstPass.filter((p) => {
  //       return (
  //         p.ID !== "11" &&
  //         p.ID !== "18" &&
  //         p.ID !== "60" &&
  //         p.ID !== "82" &&
  //         p.ID !== "140" &&
  //         p.ID !== "185" &&
  //         p.ID !== "1111" &&
  //         p.ID !== "1392" &&
  //         p.ID !== "1124" &&
  //         p.ID !== "1331" &&
  //         p.ID !== "1355" &&
  //         p.ID !== "1397"
  //       );
  //     });

  //     return secondPass;
  //   }

  //   private htmlFor(u: string): SafeHtml {
  //     if (u.includes("youtube.com/v/")) {
  //       return `<object width="580" height="351"><param name="movie" value="http://www.youtube.com/v/${this.getYoutubeId(
  //         u
  //       )}?fs=1&amp;hl=en_US&amp;rel=0"></param><param name="allowFullScreen" value="true"></param><param name="allowscriptaccess" value="always"></param><embed src="http://www.youtube.com/v/${this.getYoutubeId(
  //         u
  //       )}?fs=1&amp;hl=en_US&amp;rel=0" type="application/x-shockwave-flash" allowscriptaccess="always" allowfullscreen="true" width="580" height="351"></embed></object>`;
  //     }

  //     if (u.includes("youtube")) {
  //       return `<iframe width="580" height="435" src="http://www.youtube.com/embed/${this.getYoutubeId(
  //         u
  //       )}" frameborder="0" allowfullscreen></iframe>`;
  //     }

  //     if (u.includes("vimeo.com")) {
  //       return `<iframe src="http://player.vimeo.com/video/${this.getVimeoId(
  //         u
  //       )}?byline=0&amp;portrait=0" width="580" height="387" frameborder="0"></iframe>`;
  //     }

  //     if (u.includes("bandcamp.com/Embedded")) {
  //       return `<iframe style="border: 0; width: 100%; height: 120px;" src="${u}" seamless></iframe>`;
  //     }

  //     if (u.includes(".soundcloud.com")) {
  //       return `<iframe width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/${this.getSoundcloudId(
  //         u
  //       )}&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>`;
  //     }
  //     return;
  //   }

  //   private getYoutubeId(url: string): string {
  //     const regExp =
  //       /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  //     const match = url.match(regExp);

  //     return match && match[2].length === 11 ? match[2] : null;
  //   }

  //   private getVimeoId(url: string): string {
  //     const regExp = /http:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/;
  //     const match = url.match(regExp);
  //     if (match && match[2]) {
  //       return match[2];
  //     } else {
  //       return url.split("?")[0].split("/").reverse()[0];
  //     }
  //   }
  //   private getSoundcloudId(url: string): string {
  //     const urlFixed = decodeURIComponent(url)
  //       .replace("&amp;", "&")
  //       .replace("&amp;", "&")
  //       .replace("amp;", "");

  //     const id = urlFixed.split("/").reverse()[0];
  //     return id;
  //   }
}
