import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PostContainerComponent } from './components/post-container/post-container.component';
import { PostListContainerComponent } from './components/post-list-container/post-list-container.component';
import { PostResolver } from './resolver/post.resolver';

const routes: Routes = [
  {
    path: "",
    component: PostListContainerComponent,
  },
  {
    path: ":postSlug",
    component: PostContainerComponent,
    resolve: { post: PostResolver },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
