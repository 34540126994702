import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Post } from 'src/app/model/post';

@Component({
  selector: "app-post-container",
  templateUrl: "./post-container.component.html",
  styleUrls: ["./post-container.component.scss"],
})
export class PostContainerComponent implements OnInit {
  post$: Observable<Post>;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.post$ = this.route.data.pipe(map((param) => param.post));
  }
}
