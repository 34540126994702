import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { Post } from 'src/app/model/post';
import { IntersectionObserverService } from 'src/app/services/intersection-observer.service';

@Component({
  selector: "tdj-post",
  templateUrl: "./post.component.html",
  styleUrls: ["./post.component.scss"],
})
export class PostComponent implements AfterViewInit, OnInit {
  @Input() post: Post;

  @ViewChild("ob", { read: ElementRef, static: true }) ob: ElementRef;
  isVisible$: Observable<boolean>;

  private _html: SafeHtml;

  constructor(
    private sanitizer: DomSanitizer,
    private intersectionObsService: IntersectionObserverService
  ) {}

  ngOnInit(): void {
    this._html = this.safeHtml(this.post.content);
  }

  ngAfterViewInit(): void {
    this.isVisible$ = this.intersectionObsService.createAndObserve(this.ob);
  }

  get link(): string[] {
    return [`/${this.post.slug}`];
  }

  get html(): SafeHtml {
    return this._html;
  }

  private safeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
